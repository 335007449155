import { Avatar, Box, Stack, Text, useColorModeValue } from '@chakra-ui/react';

export default function Review() {
    return (
        <Stack
            py={16}
            px={8}
            spacing={{ base: 8, md: 10 }}
            align={'center'}
            direction={'column'}>
            <Text
                fontSize={{ base: 'xl', md: '2xl' }}
                textAlign={'center'}
                maxW={'3xl'}>
                Our DTC team kept struggling with setting up checkout links for different variants and a guy made a sheet that simplified it. Same guy decided that it would be fun putting it in React. We love using the app and if you do too - shoot us a message (socials below). That would help us put an actual review here :)
            </Text>
            <Box textAlign={'center'}>
                <Avatar
                    src={
                        'https://themanchev.com/wp-content/uploads/2022/11/66731288122__7D3CED19-E327-40D9-9AE6-EDD2907E0273-1-1.jpg'
                    }
                    alt={'Jenny Wilson'}
                    mb={2}
                />

                <Text fontWeight={600}>Alex & Andreas</Text>
                <Text fontSize={'sm'} color={useColorModeValue('gray.400', 'gray.400')}>
                    the guys who made click cart
                </Text>
            </Box>
        </Stack>
    );
}