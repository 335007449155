import React from 'react';
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';

import {
  BrowserRouter, Routes,
  Route,
} from "react-router-dom";
import Footer from './Pages/Sections-Home/footer'
import Home from './Pages/home';
import Web from './Pages/web';
import Contact from './Pages/contact';
import FAQ from './Pages/faq';
import Doc from './Pages/doc';


function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/web" element={<Web />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/documentation" element={<Doc />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </ChakraProvider >
  );
}

export default App;
