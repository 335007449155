import React from 'react'
import { WebPanel } from './Sections-Web/BuilderPanel';
import {
    Box,
    Container,
} from '@chakra-ui/react'

import NavWeb from './Sections-Web/navweb';

export default function Web() {

    return (
        <><NavWeb />
            <Box
                bg='green.700'
                w='100%'
                h={100}
                sx={{ position: 'absolute', zIndex: -1 }}
            >
            </Box>
            <Container maxW='container.lg'>
                <Box
                    bg='white'
                    color='blue.900'
                    boxShadow='lg'
                    borderRadius='md'
                    minH={300}
                >
                    <WebPanel />
                </Box>
            </Container>
            <Box
                w='100%'
                h={10}
                sx={{ position: 'relative', zIndex: -1 }}
            />
        </>
    );
}