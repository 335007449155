import {
    Box,
    VStack,
    Button,
    Flex,
    Divider,
    chakra,
    Grid,
    GridItem,
    Container,
    Link,
    useColorModeValue
} from '@chakra-ui/react';
import { } from '@chakra-ui/react';

interface FeatureProps {
    heading: string;
    text: string;
}

const Feature = ({ heading, text }: FeatureProps) => {
    return (
        <GridItem>
            <chakra.h3 fontSize="xl" fontWeight="600">
                {heading}
            </chakra.h3>
            <chakra.p>{text}</chakra.p>
        </GridItem>
    );
};

export default function Pitch() {
    return (
        <Box as={Container} maxW="100%" py={12} px={{ base: '20px', sm: '20px', md: '140px' }}
            bg={useColorModeValue('gray.50', '#171923')}>
            <Grid
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                gap={4}>
                <GridItem colSpan={1}>
                    <VStack alignItems="flex-start" spacing="20px">
                        <chakra.h2 fontSize="3xl" fontWeight="700">
                            Our Pitch - It's Free, Easy & It Works
                        </chakra.h2>
                        <Link href="/web">
                            <Button colorScheme="green" size="md">
                                Try the web version, no install →
                            </Button></Link>
                    </VStack>
                </GridItem>
                <GridItem>
                    <Flex>
                        <chakra.p>
                            <br /> We actually dislike long landers and endless feature lists.<br />Here's a quick recap of what Click Cart does:
                        </chakra.p>
                    </Flex>
                </GridItem>
            </Grid>
            <Divider mt={12} mb={12} />
            <Grid
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(4, 1fr)',
                }}
                gap={{ base: '8', sm: '12', md: '16' }}>
                <Feature
                    heading={'🛒 Products to cart'}
                    text={'Send your users a link, which magically adds a product(s) of your choice to their cart.                    '}
                />
                <Feature
                    heading={'🕶 Discount codes via url'}
                    text={'Let users add a discount code via url, requires code creation in the shopify admin.'}
                />
                <Feature
                    heading={'🔗 Redirect to page'}
                    text={'Let the user arrive at checkout, cart, collection or anywhere else on your site.'}
                />
                <Feature
                    heading={'💥 All of the above'}
                    text={'Yep - add products, discount and redirects. All with one link.'}
                />
            </Grid>
        </Box>
    );
}