import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    Switch,
    useColorMode,
} from '@chakra-ui/react';
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
} from '@chakra-ui/icons';


export default function NavWeb() {
    const { isOpen, onToggle } = useDisclosure();
    const { colorMode, toggleColorMode } = useColorMode()
    const isDark = colorMode === 'dark'


    return (
        <Box textAlign="center" fontSize="xl">
            <Box>
                <Flex

                    color={useColorModeValue('#2D2D2C', 'white')}
                    minH={'40px'}
                    bg='green.700'

                    pt={{ base: 6 }}
                    pb={{ base: 3 }}
                    px={{ base: 12 }}

                    align={'center'}>

                    <Flex flex={{ base: 1 }} justify={{ base: 'left', md: 'start' }}>
                        <Link href="/"
                            _hover={{
                                textDecoration: 'none',
                            }}>
                            <Text
                                textAlign={useBreakpointValue({ base: 'left', md: 'left' })}
                                fontFamily={'heading'}
                                fontSize="xlg"
                                fontWeight="800"
                                color="#ffffff">
                                Click Cart
                            </Text>
                        </Link>

                    </Flex>

                    <Stack
                        flex={{ base: 1, md: 0 }}
                        justify={'flex-end'}
                        direction={'row'}
                        spacing={6}>
                        <Button
                            display={{ base: 'none', md: 'flex' }}
                            as={'a'}
                            fontSize={'sm'}
                            fontWeight={400}
                            color="#ffffff"
                            variant={'link'}
                            href={'/documentation'}>
                            Help
                        </Button>
                        <Switch
                            color="green"
                            isChecked={isDark}
                            onChange={toggleColorMode}
                            px={2}
                            pt={{ base: '3', md: '0' }}
                            display={['none', 'flex', 'flex', 'flex']}
                        />
                        <Flex
                            flex={{ base: 1, md: 'auto' }}
                            ml={{ base: -2 }}
                            justifyContent="right"
                            display={{ base: 'flex', md: 'none' }}>
                            <IconButton
                                onClick={onToggle}
                                icon={
                                    isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                                }
                                variant={'ghost'}
                                aria-label={'Toggle Navigation'}
                            />
                        </Flex>
                    </Stack>

                </Flex>

                <Collapse in={isOpen} animateOpacity>
                    <MobileNav />
                </Collapse>
            </Box>
        </Box>
    );
}



const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            display={{ md: 'none' }}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}>
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

interface NavItem {
    label: string;
    subLabel?: string;
    children?: Array<NavItem>;
    href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
    {
        label: 'Contact',
        href: '/contact',
    },
    {
        label: 'Documentation',
        href: '/documentation',
    },
    {
        label: 'FAQs',
        href: '/faq',
    },


];