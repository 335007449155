import React, { useEffect, useState } from 'react';
import {
    Tooltip,
    Flex,
    Input,
    HStack,
    VStack,
    Heading,
    Spacer,
    Box,
    InputGroup,
    IconButton,
    InputLeftAddon,
    Checkbox,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Text,
    Button,
    useClipboard,
} from '@chakra-ui/react';

import { DeleteIcon, InfoOutlineIcon, LinkIcon } from '@chakra-ui/icons';

import { ProductIcon, DiscountIcon } from './CustomIcons';
import { v4 as uuidv4 } from 'uuid';

const Builder = props => (
    <VStack
        width='100%'
        px={{ base: 0, sm: 6 }}
        py={10}
        spacing={4}
        align='stretcentech'
        {...props}
    />
);

const ProductListItem = ({ product, isSub, onChange, onDelete }) => (
    <Flex
        flexDir='row'
        alignItems='center'
        bg='#27674908'
        mt={2}
        py={2}
        borderWidth='1px'
        borderRadius='lg'
    >
        <Input
            bg='white'
            width='auto'
            variant='outline'
            ////// TODO: onChange is fucked

            onChange={e =>
                onChange({
                    productId: e.target.value,
                    qnty: product.qnty,
                    subid: product.subid,
                })
            }
            ml={2}
            placeholder='Add Product Variant ID'
        />

        <Spacer />
        {isSub && (
            <Input
                width='250px'
                bg='white'
                placeholder='Subscription Selling Plan ID'
                mr='15px'
                onChange={e =>
                    onChange({
                        productId: product.productId,
                        qnty: product.qnty,
                        subid: e.target.value,
                    })
                }
            />
        )}
        <NumberInput
            w='75px'
            min={1}
            bg='white'
            value={product.qnty || 1}
            onChange={value =>
                onChange({
                    productId: product.productId,
                    qnty: value,
                    subid: product.subid,
                })
            }
        >
            <NumberInputField />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
        <IconButton
            variant='ghost'
            aria-label='Remove Product'
            colorScheme='#2767490d'
            icon={<DeleteIcon />}
            onClick={() => onDelete()}
        />
    </Flex>
);

const createLink = (domain, products, redirect, specialCode, isDiscount, isSub) => {
    let discountUrl = '';
    let redirectUrl = '';
    let urlFix = '';
    let productUrlString = '';
    let productsString = '';

    if (specialCode !== '' && isDiscount === true) {
        if (redirect !== '') {
            discountUrl = `/discount/${specialCode}?redirect=`;
        } else if (specialCode !== '') {
            discountUrl = `/discount/${specialCode}`;
        }
    } else {
        discountUrl = '';
    }

    if (redirect !== '') {
        redirectUrl = `${redirect}`;
    } else {
        redirectUrl = '';
    }

    if (isSub) {
        productsString = products
            .map(({ productId, qnty, subid }) => `items[][id]=${productId}&items[][quantity]=${qnty}&items[][selling_plan]=${subid}&`)
            .join('');

        productUrlString = '/cart/add?' + productsString + 'return_to'
        urlFix = '/cart/add?'

    }
    else {
        productsString = products
            .map(({ productId, qnty }) => `updates[${productId}]=${qnty}&`)
            .join('');

        productUrlString = '/cart/update?' + productsString + 'return_to'
        urlFix = '/cart/update?'
    }



    let productUrl = ''
    if (productUrlString !== '/cart/update?return_to') {
        productUrl = urlFix + productsString + 'return_to'
    } else {
        productUrl = ''
    }


    console.log(productsString);
    console.log(productUrl);

    return [domain, productUrl, discountUrl, redirectUrl].join('');
};

export function WebPanel() {
    const [isProduct, setisProduct] = useState(true);
    const [isDiscount, setisDiscount] = useState(true);
    const [redirect, setRedirect] = React.useState('');
    const [specialCode, setSpecialCode] = React.useState('');
    const [copyUrl, setCopyUrl] = React.useState('');
    const { hasCopied, onCopy, CopyUrl } = useClipboard(CopyUrl);
    const [isSub, setisSub] = useState(false);
    const [products, setProducts] = useState([]);

    const createProduct = () =>
        setProducts([
            ...products,
            { uuid: uuidv4(), productId: '', qnty: 1, subid: '' },
        ]);

    const deleteProduct = uuid => () =>
        setProducts([...products].filter(p => p.uuid !== uuid));
    const changeProduct = uuid => ({ productId, qnty, subid }) => {
        console.log(uuid);
        console.log({ productId, qnty, subid });

        setProducts(
            [...products].map(p =>
                p.uuid === uuid ? { uuid, productId, qnty, subid } : p
            )
        );
    };
    useEffect(() => {
        let domain = ''; ///// TODO
        setCopyUrl(createLink(domain, products, redirect, specialCode, isDiscount, isSub));
    }, [products, redirect, specialCode, isDiscount]);

    useEffect(() => {
        console.log(products);
    }, [products]);
    return (
        <>
            <Builder>
                <HStack
                    spacing={{ base: '0', md: '10' }}
                    px={6}
                    display={{ base: 'block', md: 'flex' }}
                >
                    <Box
                        cursor='pointer'
                        w={{ base: '100%', md: '50%' }}
                        pos='relative'
                        bg={isProduct ? '#48bb7812' : '#27674908'}
                        borderColor={isProduct ? '#333333' : '#e1e2e7'}
                        px={6}
                        pt={15}
                        pb={30}
                        borderWidth='2px'
                        borderRadius='lg'
                        onClick={() => {
                            setisProduct(!isProduct);
                        }}
                    >
                        <HStack spacing={10} px={2}>
                            <Box w='10%'>
                                <ProductIcon w={8} h={8} aria-label='Product Option' />
                            </Box>
                            <Box w='90%'>
                                {' '}
                                <Text fontSize='xl' pt={15} fontWeight='bold' color='#333333'>
                                    Product
                                </Text>
                                <Text pt={2}>
                                    Add one or more products to the users cart and redirects them
                                    to a page.
                                </Text>
                            </Box>
                        </HStack>
                        <Checkbox
                            value='purl'
                            colorScheme='green'
                            size='lg'
                            pos='absolute'
                            right='15px'
                            top='15px'
                            style={{ pointerEvents: 'none' }}
                            isChecked={isProduct}
                        ></Checkbox>
                    </Box>
                    <Box
                        cursor='pointer'
                        w={{ base: '100%', md: '50%' }}
                        pos='relative'
                        bg={isDiscount ? '#48bb7812' : '#27674908'}
                        borderColor={isDiscount ? '#333333' : '#e1e2e7'}
                        px={6}
                        pt={15}
                        pb={30}
                        borderWidth='2px'
                        borderRadius='lg'
                        onClick={() => {
                            setisDiscount(!isDiscount);
                        }}
                    >
                        <HStack spacing={10} px={2}>
                            <Box w='10%'>
                                <DiscountIcon w={8} h={8} aria-label='Discount Option' />
                            </Box>
                            <Box w='90%'>
                                {' '}
                                <Text fontSize='xl' fontWeight='bold' pt={15} color='#333333'>
                                    Discount Code
                                </Text>
                                <Text pt={2}>
                                    Add Shopify discount code to the users cart and redirects them
                                    to a page.
                                </Text>
                            </Box>
                        </HStack>
                        <Checkbox
                            value='durl'
                            colorScheme='green'
                            size='lg'
                            pos='absolute'
                            right='15px'
                            top='15px'
                            style={{ pointerEvents: 'none' }}
                            isChecked={isDiscount}
                        ></Checkbox>
                    </Box>
                </HStack>

                <Box px={6} pt={6}>
                    <Heading size='md' color='#333333'>
                        Products
                    </Heading>
                    <Text pt={2} mb={2}>
                        Select one or more products to be added to the users cart via the
                        link.
                    </Text>
                    {products.map(product => (
                        <ProductListItem
                            key={product.uuid}
                            product={product}
                            isSub={isSub}
                            onDelete={deleteProduct(product.uuid)}
                            onChange={changeProduct(product.uuid)}
                        />
                    ))}
                    <HStack spacing={2} mt={15} display={{ base: 'block', md: 'flex' }}>
                        <Button
                            _hover={{
                                background: '#333333',
                                color: 'white',
                            }}
                            color='white'
                            bg='#276749'
                            onClick={() => createProduct()}
                            px={6}
                        >
                            Add a product +
                        </Button>
                        <Box
                            display={isProduct ? 'flex' : 'none'}
                            cursor='pointer'
                            w='100%'
                            borderWidth='0px'
                            borderRadius='lg'
                            justifyContent={{ base: 'left', md: 'flex-end' }}
                            onClick={() => {
                                setisSub(!isSub);
                            }}
                        >
                            <HStack spacing={2}>
                                <Text>Subscription products?</Text>
                                <Tooltip
                                    label='Subscription products require a Subscription Selling Plan ID. You can find more information in the subscription app you are using.'
                                    fontSize='md'
                                >
                                    <InfoOutlineIcon />
                                </Tooltip>
                                <Checkbox
                                    colorScheme='green'
                                    size='lg'
                                    style={{ pointerEvents: 'none' }}
                                    isChecked={isSub}
                                ></Checkbox>
                            </HStack>
                        </Box>
                    </HStack>
                </Box>

                <HStack
                    pt={6}
                    spacing={{ base: '0', md: '10' }}
                    px={6}
                    display={{ base: 'block', md: 'flex' }}
                >
                    <Box
                        w={{ base: '100%', md: '50%' }}
                        bg='#27674908'
                        px={6}
                        pb={25}
                        borderWidth='1px'
                        borderRadius='lg'
                    >
                        <Heading size='md' pt={5} color='#333333'>
                            Redirection
                        </Heading>
                        <Text pt={2}>Select where the user will be redirect.</Text>
                        <InputGroup pt={30}>
                            <InputLeftAddon>
                                <LinkIcon />
                            </InputLeftAddon>
                            <Input
                                bg='white'
                                placeholder='/collections/all/'
                                value={redirect}
                                onChange={ev => setRedirect(ev.target.value)}
                            />
                        </InputGroup>
                    </Box>

                    <Box
                        w={{ base: '100%', md: '50%' }}
                        bg='#27674908'
                        px={6}
                        pb={25}
                        borderWidth='1px'
                        borderRadius='lg'
                        display={isDiscount ? 'block' : 'none'}
                    >
                        <Heading size='md' pt={5} color='#333333'>
                            Discount Code
                        </Heading>
                        <Text pt={2}>Apply a discount code to the users cart.</Text>
                        <InputGroup width='auto' pt={30}>
                            <InputLeftAddon>%</InputLeftAddon>

                            <Input
                                bg='white'
                                value={specialCode}
                                onChange={ev => setSpecialCode(ev.target.value)}
                                placeholder='Enter Discount Code'
                            />
                        </InputGroup>
                    </Box>
                </HStack>
                <Flex pt={6} px={6}>
                    <InputGroup>
                        <InputLeftAddon fontWeight='bold' color='white' bg='#276749'>
                            https://domain.com
                        </InputLeftAddon>
                        <Input value={copyUrl} isReadOnly placeholder={copyUrl} />
                    </InputGroup>
                    <Button onClick={onCopy} ml={2} bg='#f2f2f2'>
                        {hasCopied ? 'Copied' : 'Copy'}
                    </Button>
                </Flex>
            </Builder>
        </>
    );
}
