import React from 'react';

import { CCHero } from './Sections-Home/hero';
import Nav from './Sections-Home/nav';
import Pitch from './Sections-Home/pitch'
import Review from './Sections-Home/review'


export default function Home() {

    return (
        <>
            <Nav />
            <CCHero />
            <Pitch />
            <Review />
        </>
    );
}